/*
generated by constants.rake in production

See application.rb and CONSTANTS_JS
*/

/* eslint-disable quotes,prettier/prettier */

export const FAVORITE_LIMIT_ENABLED_AT = "2025-01-07T08:00:00.000+00:00";
export const FREE_FAVORITE_ARTISTS_COUNT = 10;
export const DEFAULT_FAVORITE_ARTISTS_COUNT = 50;
export const SOURCE_ON_REGISTRATION_COOKIE_KEY = "sor";
export const RETURN_AFTER_LOGIN_COOKIE_KEY = "ral";
export const AILTYPE_ARTIST = 2;
export const AILTYPE_LOT = 3;
export const CURRENCIES = {"eur":{"id":48,"iso1":"€","iso3":"eur","iso3ForIntl":"eur","defaultCurrency":true,"intConversion":true},"usd":{"id":154,"iso1":"$","iso3":"usd","iso3ForIntl":"usd","defaultCurrency":true,"intConversion":true},"btc":{"id":176,"iso1":"₿","iso3":"btc","iso3ForIntl":"btc","defaultCurrency":true},"eth":{"id":178,"iso1":"⧫","iso3":"eth","iso3ForIntl":"eth","defaultCurrency":true},"gbp":{"id":52,"iso1":"£","iso3":"gbp","iso3ForIntl":"gbp","defaultCurrency":true,"intConversion":true},"cny":{"id":32,"iso1":"￥","iso3":"cny","iso3ForIntl":"rmb","defaultCurrency":true,"intConversion":true},"ils":{"id":67,"iso1":"₪ (ILS)","iso3":"ils","iso3ForIntl":"ils","intConversion":true},"sek":{"id":132,"iso1":"KR (SEK)","iso3":"sek","iso3ForIntl":"sek","intConversion":true},"chf":{"id":30,"iso1":"CHF","iso3":"chf","iso3ForIntl":"chf","intConversion":true},"cad":{"id":28,"iso1":"$ CA","iso3":"cad","iso3ForIntl":"cad","intConversion":true}};
export const ANALYTICS_FROM_PROMOTION_NOTIFY = "promotion_notify";
export const ANALYTICS_FROM_ARTMARKETINSIGHT_ARTICLE = "ail_artmarketinsight_article";
export const ANALYTICS_FROM_SUBSCRIPTIONS_DATA = "subscriptions-data";
export const ANALYTICS_FROM_SUBSCRIPTIONS_DATA_NEW_COMER = "subscriptions-data-new-comer";
export const ANALYTICS_FROM_SUBSCRIPTIONS_STORE = "subscriptions-store";
export const ANALYTICS_FROM_SUBSCRIPTIONS_STORE_NEW_COMER = "subscriptions-store-new-comer";
export const ANALYTICS_FROM_AIL_LANDINGPAGE = "ail_landingpage";
export const ANALYTICS_FROM_FAQ_PANEL = "faq_panel";
export const ANALYTICS_FROM_FOOTER = "footer";
export const ANALYTICS_FROM_HOMEPAGE_MARKETPLACE = "homepage_marketplace";
export const ANALYTICS_FROM_ARTIST = "artist";
export const ANALYTICS_FROM_ARTIST_TOUR = "artist_tour";
export const ANALYTICS_FROM_FUTURE_SALES = "future_sales";
export const ANALYTICS_FROM_HOMEPAGE = "homepage";
export const ANALYTICS_FROM_LOT_DETAIL = "lot_detail";
export const ANALYTICS_FROM_LOT_LIST = "lot_list";
export const ANALYTICS_FROM_SALE = "sale";
export const ANALYTICS_FROM_MY_ARTPRICE = "my_artprice";
export const ANALYTICS_FROM_CLASSIFIED_DETAIL = "classified_detail";
export const ANALYTICS_FROM_STORE_CONTACT = "store_contact";
export const ANALYTICS_FROM_HEADER_ACCOUNT = "header_account";
export const ANALYTICS_FROM_HEADER_MENU = "header_menu";
export const ANALYTICS_FROM_HEADER_NOTIFICATIONS = "header_notifications";
export const ANALYTICS_FROM_HEADER_MESSAGES = "header_messages";
export const ANALYTICS_FROM_HEADER_FAVORITES = "header_favorites";
export const ANALYTICS_FROM_HEADER_SEARCHBAR = "header_searchbar";
export const ANALYTICS_FROM_SEARCH_UNIVERSAL = "search_universal";
export const ANALYTICS_FROM_HEADER_LANGS = "header_langs";
export const ANALYTICS_FROM_HEADER_PRICING = "header_pricing";
export const ANALYTICS_FROM_NEWSLETTER = "newsletter";
export const ANALYTICS_FROM_PUSH = "push";
export const ANALYTICS_FROM_AUCTIONEERS_PAST_SALES = "auctioneer-center-past-sales";
export const ANALYTICS_FROM_AUCTIONEERS_FUTURE_SALES = "auctioneer-center-future-sales";
export const ANALYTICS_FROM_AUCTIONEERS_SEND_DATA = "auctioneer-center-send-data";
export const ANALYTICS_FROM_AUCTIONEERS_AGREEMENT = "auctioneer-center-agreement";
export const ANALYTICS_FROM_AUCTIONEERS_SUBSCRIPTIONS_ADS = "auctioneer-center-subscriptions-ads";
export const ANALYTICS_FROM_AUCTIONEERS_CONTACT = "auctioneer-center-contact";
export const ANALYTICS_FROM_AUCTIONEERS_SALE = "auctioneer-center-sale";
export const ANALYTICS_FROM_AUCTIONEERS_LOT = "auctioneer-center-lot";
export const ANALYTICS_CLICK_OPERATION_SUBSCRIPTION_CONTACT = "subscription_contact";
export const ANALYTICS_CLICK_OPERATION_SUBSCRIPTION_PERIOD = "subscription_period";
export const ANALYTICS_CLICK_OPERATION_SUBSCRIPTION_ENABLE_IMAGES = "subscription_enable_images";
export const ANALYTICS_CLICK_OPERATION_SUBSCRIPTION_DISABLE_IMAGES = "subscription_disable_images";
export const ANALYTICS_CLICK_OPERATION_TO_SUBSCRIPTION = "to_subscription";
export const ANALYTICS_CLICK_OPERATION_TO_SUBSCRIPTION_NEW_COMER = "to_subscription_new_comer";
export const ANALYTICS_CLICK_OPERATION_TO_REGISTRATION = "to_registration";
export const ANALYTICS_CLICK_OPERATION_TOGGLE_RENEW_UPGRADE = "toggle_renew_upgrade";
export const ANALYTICS_CLICK_OPERATION_NB_USERS = "nb_users";
export const ANALYTICS_CLICK_OPERATION_NB_USERS_MORE_THAN_10 = "nb_users_more_than_10";
export const ANALYTICS_CLICK_OPERATION_MORE_INFORMATIONS = "more_informations";
export const ANALYTICS_CLICK_OPERATION_PROMOCODE_VALIDATE = "promocode_validate";
export const ANALYTICS_CLICK_OPERATION_PROMOCODE_CLEAR = "promocode_clear";
export const ANALYTICS_CLICK_OPERATION_SELECT_PRODUCT = "select_product";
export const ANALYTICS_CLICK_OPERATION_TOGGLE_IMAGES = "toggle_images";
export const ANALYTICS_CLICK_OPERATION_TO_CART = "to_cart";
export const ANALYTICS_CLICK_OPERATION_TO_LANDINGPAGE = "to_landingpage";
export const ANALYTICS_CLICK_OPERATION_TO_LANDINGPAGE_CONSIGNMENT = "to_landingpage_consignment";
export const ANALYTICS_CLICK_OPERATION_TO_CGV = "to_cgv";
export const ANALYTICS_CLICK_OPERATION_TO_PRIVACY = "to_privacy";
export const ANALYTICS_CLICK_OPERATION_TO_RETRACT = "to_retract";
export const ANALYTICS_CLICK_OPERATION_TO_COOKIES = "to_cookies";
export const ANALYTICS_CLICK_OPERATION_TO_VIDEO = "to_video";
export const ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_OBLIGATIONS = "to_marketplace_obligations";
export const ANALYTICS_CLICK_OPERATION_TO_X = "to_x";
export const ANALYTICS_CLICK_OPERATION_TO_FB = "to_fb";
export const ANALYTICS_CLICK_OPERATION_TO_LINKEDIN = "to_linkedin";
export const ANALYTICS_CLICK_OPERATION_TO_DDC = "to_ddc";
export const ANALYTICS_CLICK_OPERATION_TO_PRESS_RELEASE = "to_press_release";
export const ANALYTICS_CLICK_OPERATION_TO_INTERPOL = "to_interpol";
export const ANALYTICS_CLICK_OPERATION_TO_AMCI = "to_amci";
export const ANALYTICS_CLICK_OPERATION_TO_RECRUITMENT = "to_recruitment";
export const ANALYTICS_CLICK_OPERATION_TO_HOWTO_SEARCH = "to_howto_search";
export const ANALYTICS_CLICK_OPERATION_TO_ARTINVESTMENT = "to_artinvestment";
export const ANALYTICS_CLICK_OPERATION_DISPLAY_SEARCH_BAR = "display_search_bar";
export const ANALYTICS_CLICK_OPERATION_TO_ARTMARKETINSIGHT_ARTICLE = "to_artmarketinsight_article";
export const ANALYTICS_CLICK_OPERATION_TO_ARTMARKETINSIGHT_ARTICLES = "to_artmarketinsight_articles";
export const ANALYTICS_CLICK_OPERATION_TO_ARTMARKETINSIGHT_ARTICLE_SEARCH = "to_artmarketinsight_article_search";
export const ANALYTICS_CLICK_OPERATION_TO_ARTMARKETINSIGHT_ARTIST_ARTICLES = "to_artmarketinsight_artist_articles";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOT_AIL_FROM_HEADER = "to_artist_future_lot_ail_from_header";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOT_AIL_FROM_LOTS = "to_artist_future_lot_ail_from_lots";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_BIOGRAPHY = "to_artist_biography";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_SIGNATURES = "to_artist_signatures";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOTS_LIST = "to_artist_future_lots_list";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOTS_LIST_BY_CATEGORY = "to_artist_future_lots_list_by_category";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOTS_LIST_NOT_SIGNED = "to_artist_future_lots_list_not_signed";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOT = "to_artist_future_lot";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_PAST_LOTS_LIST = "to_artist_past_lots_list";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_PAST_LOTS_LIST_BY_CATEGORY = "to_artist_past_lots_list_by_category";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_PAST_LOTS_LIST_NOT_SIGNED = "to_artist_past_lots_list_not_signed";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_PAST_LOT = "to_artist_past_lot";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_INDEXES = "to_artist_indexes";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_CLASSIFIEDS_LIST = "to_artist_classifieds_list";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_CLASSIFIED = "to_artist_classified";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_STORES_LIST = "to_artist_stores_list";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_STORE = "to_artist_store";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_ARTICLES_LIST = "to_artist_articles_list";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_COLLABORATION = "to_artist_collaboration";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_RECOMMANDATION = "to_artist_recommandation";
export const ANALYTICS_CLICK_OPERATION_ARTIST_MENU = "artist_menu";
export const ANALYTICS_CLICK_FIGURES = "artist_figures";
export const ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_SEARCH = "to_marketplace_search";
export const ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_HOW_TO_BUY = "to_marketplace_how_to_buy";
export const ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_HOW_TO_AUCTION = "to_marketplace_how_to_auction";
export const ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_FORM = "to_marketplace_form";
export const ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_HOW_TO_SELL = "to_marketplace_how_to_sell";
export const ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_PRICING = "to_marketplace_pricing";
export const ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_STORES = "to_marketplace_stores";
export const ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_STORE = "to_marketplace_store";
export const ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_CLASSIFIEDS_ALL = "to_marketplace_classifieds_all";
export const ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_ARTIST_CLASSIFIEDS = "to_marketplace_artist_classifieds";
export const ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_CLASSIFIEDS_AUCTIONS = "to_marketplace_classifieds_auctions";
export const ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_MANAGE_WINNING_AUCTIONS = "to_marketplace_manage_winning_auctions";
export const ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_MANAGE_MODIFICATION_REQUIRED = "to_marketplace_manage_modification_required";
export const ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_MANAGE_OFFLINE = "to_marketplace_manage_offline";
export const ANALYTICS_CLICK_OPERATION_TO_AIL_URL = "to_ail_url";
export const ANALYTICS_CLICK_OPERATION_TO_AIL_HOME = "to_ail_home";
export const ANALYTICS_CLICK_OPERATION_TO_CLASSIFIED_DETAIL = "to_classified_detail";
export const ANALYTICS_CLICK_OPERATION_TO_AUCTIONEER_HOME = "to_auctioneer_home";
export const ANALYTICS_CLICK_OPERATION_TO_AUCTIONEER_LOT = "to_auctioneer_lot";
export const ANALYTICS_CLICK_OPERATION_TO_AUCTIONEER_SALE = "to_auctioneer_sale";
export const ANALYTICS_CLICK_OPERATION_TO_SALE_DETAIL = "to_sale_detail";
export const ANALYTICS_CLICK_OPERATION_TO_SELLER_EMAIL = "to_seller_email";
export const ANALYTICS_CLICK_OPERATION_TO_SELLER_URL = "to_seller_url";
export const ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_HOME = "to_marketplace_home";
export const ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_MESSAGES = "to_marketplace_messages";
export const ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_AUTO_REDIRECT = "to_marketplace_auto_redirect";
export const ANALYTICS_CLICK_OPERATION_TO_SEARCH_PAGE = "to_search_page";
export const ANALYTICS_CLICK_OPERATION_TO_SEARCH_ARTISTS_PAGE = "to_search_artists_page";
export const ANALYTICS_CLICK_OPERATION_TO_SEARCH_LOTS_PAGE = "to_search_lots_page";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_INDEX = "to_artist_index";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_DETAIL = "to_artist_detail";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_SEARCH = "to_artist_search";
export const ANALYTICS_CLICK_OPERATION_TO_FUTURE_SALES = "to_future_sales";
export const ANALYTICS_CLICK_OPERATION_TO_HOME = "to_home";
export const ANALYTICS_CLICK_OPERATION_TO_DEMO = "to_demo";
export const ANALYTICS_CLICK_OPERATION_TO_AMI_HOME = "to_ami_home";
export const ANALYTICS_CLICK_OPERATION_TO_REPORT = "to_report";
export const ANALYTICS_CLICK_OPERATION_TO_REPORTS_HOME = "to_reports_home";
export const ANALYTICS_CLICK_OPERATION_TO_REPORT_SEARCH = "to_report_search";
export const ANALYTICS_CLICK_OPERATION_TO_REPORTS_ARCHIVE = "to_reports_archive";
export const ANALYTICS_CLICK_OPERATION_TO_EVENTS_HOME = "to_events_home";
export const ANALYTICS_CLICK_OPERATION_TO_EVENT = "to_event";
export const ANALYTICS_CLICK_OPERATION_TO_PRICING = "to_pricing";
export const ANALYTICS_CLICK_OPERATION_TO_PRICING_UPGRADE = "to_pricing_upgrade";
export const ANALYTICS_CLICK_OPERATION_TO_ESTIMATE_HOME = "to_estimate_home";
export const ANALYTICS_CLICK_OPERATION_TO_ESTIMATE_HOWTO = "to_estimate_howto";
export const ANALYTICS_CLICK_OPERATION_TO_ARTINVESTMENT_HOME = "to_artinvestment_home";
export const ANALYTICS_CLICK_OPERATION_TO_SHOP_HOME = "to_shop_home";
export const ANALYTICS_CLICK_OPERATION_TO_CONTACT_ESTIMATE = "to_contact_estimate";
export const ANALYTICS_CLICK_OPERATION_TO_CONTACT_HOME = "to_contact_home";
export const ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_HOME = "to_account_home";
export const ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_ESTIMATE = "to_account_estimate";
export const ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_MESSAGES = "to_account_messages";
export const ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_LOTS_LISTS = "to_account_lots_lists";
export const ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_CONFIRM = "to_account_confirm";
export const ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_SITUATION = "to_account_situation";
export const ANALYTICS_CLICK_OPERATION_TO_CUSTOMER_HISTORY = "to_customer_history";
export const ANALYTICS_CLICK_OPERATION_TO_AUCTIONEER_CONTROL_CENTER = "to_auctioneer_control_center";
export const ANALYTICS_CLICK_OPERATION_TO_AUCTIONEER_AGREEMENT = "to_auctioneer_agreement";
export const ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_ARTISTS = "to_myartprice_artists";
export const ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_LOTS = "to_myartprice_lots";
export const ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_MARKETPLACE = "to_myartprice_marketplace";
export const ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_SETTINGS = "to_myartprice_settings";
export const ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_STORE = "to_myartprice_store";
export const ANALYTICS_CLICK_OPERATION_TO_STORE_EDIT = "to_store_edit";
export const ANALYTICS_CLICK_OPERATION_TO_LOGIN_PAGE = "to_login_page";
export const ANALYTICS_CLICK_OPERATION_TO_SUCCESSION = "to_succession";
export const ANALYTICS_CLICK_OPERATION_LOGOUT = "logout";
export const ANALYTICS_CLICK_OPERATION_TO_NFT_HOME = "to_nft_home";
export const ANALYTICS_CLICK_OPERATION_ADD_TO_CALENDAR = "add_to_calendar";
export const ANALYTICS_CLICK_OPERATION_CONTACT_DETAILS = "contact_details";
export const ANALYTICS_CLICK_OPERATION_VIEWING_TIMES = "viewing_times";
export const ANALYTICS_CLICK_OPERATION_TO_LOT_DETAIL = "to_lot_detail";
export const ANALYTICS_CLICK_OPERATION_TO_LOT_SEARCH = "to_lot_search";
export const ANALYTICS_CLICK_OPERATION_SCROLL = "scroll";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_FILTERS = "to_artist_filters";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_CHANGE_DISPLAY_ORDER = "to_artist_change_display_order";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_HIDE_MENU = "to_artist_hide_menu";
export const ANALYTICS_CLICK_OPERATION_TO_AIL_HOWTO = "to_ail_howto";
export const ANALYTICS_CLICK_OPERATION_TO_ARTIST_APPLY_FILTERS = "to_artist_apply_filters";
export const ANALYTICS_CLICK_OPERATION_FOLLOW_ARTIST = "follow_artist";
export const ANALYTICS_CLICK_OPERATION_FR = "fr";
export const ANALYTICS_CLICK_OPERATION_EN = "en";
export const ANALYTICS_CLICK_OPERATION_ES = "es";
export const ANALYTICS_CLICK_OPERATION_IT = "it";
export const ANALYTICS_CLICK_OPERATION_DE = "de";
export const ANALYTICS_CLICK_OPERATION_ZH = "zh";
export const ANALYTICS_OPERATION_AUCTIONEERS_TO_FUTURE_SALES = "auctioneer-center-to-future-sales";
export const ANALYTICS_OPERATION_AUCTIONEERS_TO_PAST_SALES = "auctioneer-center-to-past-sales";
export const ANALYTICS_OPERATION_AUCTIONEERS_TO_SEND_DATA = "auctioneer-center-to-send-data";
export const ANALYTICS_OPERATION_AUCTIONEERS_TO_AGREEMENT = "auctioneer-center-to-agreement";
export const ANALYTICS_OPERATION_AUCTIONEERS_TO_SUBSCRIPTIONS_ADS = "auctioneer-center-to-subscriptions-ads";
export const ANALYTICS_OPERATION_AUCTIONEERS_TO_CONTACT = "auctioneer-center-to-contact";
export const ANALYTICS_OPERATION_AUCTIONEERS_ADD_SALE = "auctioneer-center-add-sale";
export const ANALYTICS_OPERATION_OPEN_ADVANCED_SEARCH = "auctioneer-center-open-advanced-search";
export const ANALYTICS_OPERATION_ADVANCED_SEARCH = "auctioneer-center-advanced-search";
export const ANALYTICS_OPERATION_AUCTIONEERS_TO_SALE = "auctioneer-center-to-sale";
export const ANALYTICS_OPERATION_AUCTIONEERS_TO_LOT = "auctioneer-center-to-lot";
export const ANALYTICS_SECTION_SUBSCRIPTIONS_ESTIMATE = "subscriptions_estimate";
export const ANALYTICS_SECTION_SUBSCRIPTIONS_FAQ = "subscriptions_faq";
export const ANALYTICS_SECTION_SUBSCRIPTIONS_TABLE = "subscriptions_table";
export const ANALYTICS_SECTION_SUBSCRIPTIONS_CARD = "subscriptions_card";
export const ANALYTICS_SECTION_PROMO_NOTIFIER_BANNER = "banner";
export const ANALYTICS_SECTION_PROMO_NOTIFIER_MENU = "menu";
export const ANALYTICS_SECTION_SUBSCRIPTIONS_VIEW_RENEW = "RENEW";
export const ANALYTICS_SECTION_SUBSCRIPTIONS_VIEW_UPGRADE = "UPGRADE";
export const ANALYTICS_SECTION_SUBSCRIPTIONS_WARNING = "subscriptions-warning";
export const ANALYTICS_SECTION_LANDINGPAGE_CALENDAR = "landingpage_calendar";
export const ANALYTICS_SECTION_SUBSCRIPTIONS_DATA = "subscriptions-data";
export const ANALYTICS_SECTION_MYARTPRICE_PAST_LOTS = "myartprice_past_lots";
export const ANALYTICS_SECTION_MYARTPRICE_FUTURE_LOTS = "myartprice_future_lots";
export const ANALYTICS_SECTION_SCROLL_0 = "view-0-percent";
export const ANALYTICS_SECTION_SCROLL_50 = "scroll-50-percent";
export const ANALYTICS_SECTION_SCROLL_100 = "scroll-100-percent";
export const ANALYTICS_SECTION_VIEW_0 = "view-0-percent";
export const ANALYTICS_SECTION_VIEW_50 = "view-50-percent";
export const ANALYTICS_SECTION_VIEW_100 = "view-100-percent";
export const ANALYTICS_SECTION_ARTIST_TOP10 = "artist-top10";
export const ANALYTICS_SECTION_ARTIST_KNOWLEDGE = "artist-knowledge";
export const ANALYTICS_SECTION_ARTIST_INDEXES_TOOLBAR = "artist_indexes_toolbar";
export const ANALYTICS_SECTION_ARTIST_INDEXES_GRAPH = "artist_indexes_graph";
export const ANALYTICS_SECTION_ARTIST_INDEXES_BUTTON = "artist_indexes_button";
export const ANALYTICS_SECTION_ARTIST_MARKETPLACE_AD = "artist_marketplace_ad";
export const ANALYTICS_SECTION_ARTIST_MARKETPLACE_CLASSIFIEDS_LIST = "artist_marketplace_classifieds_list";
export const ANALYTICS_SECTION_ARTIST_MARKETPLACE_CATEGORY = "artist_marketplace_classifieds_category";
export const ANALYTICS_SECTION_ARTIST_ESTIMATE = "artist_estimate";
export const ANALYTICS_SECTION_ARTIST_PAST_LOTS = "artist_past_lots";
export const ANALYTICS_SECTION_ARTIST_PAST_LOTS_CATEGORY = "artist_past_lots_category";
export const ANALYTICS_SECTION_ARTIST_PAST_LOT = "artist_past_lot";
export const ANALYTICS_SECTION_ARTIST_FUTURE_LOTS = "artist_future_lots";
export const ANALYTICS_SECTION_ARTIST_FUTURE_LOTS_CATEGORY = "artist_future_lots_category";
export const ANALYTICS_SECTION_ARTIST_FUTURE_LOT = "artist_future_lot";
export const ANALYTICS_SECTION_ARTIST_HEADER = "artist_header";
export const ANALYTICS_SECTION_INDEXES_DRAWER = "artist-indexes-drawer";
export const ANALYTICS_SECTION_PAST_SALES_DRAWER = "artist-past-sales-drawer";
export const ANALYTICS_SECTION_FUTURE_SALES_DRAWER = "artist-future-sales-drawer";
export const ANALYTICS_SECTION_EMAIL_ALERT_CARD = "artist-email-alert-card";
export const ANALYTICS_SECTION_INDEXES_CARD = "artist-indexes-card";
export const ANALYTICS_SECTION_ARTIST_0 = "artist-0-percent";
export const ANALYTICS_SECTION_ARTIST_50 = "artist-50-percent";
export const ANALYTICS_SECTION_ARTIST_100 = "artist-100-percent";
export const ANALYTICS_SECTION_ARTIST_STORE = "artist_store";
export const ANALYTICS_SECTION_ARTIST_COLLABORATIONS = "artist-collaborations";
export const ANALYTICS_SECTION_ARTIST_RECOMMENDATIONS = "artist-recommendations";
export const ANALYTICS_SECTION_ARTIST_FILTERS_REDIRECT_TO_LOGIN = "artist-filters-redirect-to-login";
export const ANALYTICS_SECTION_ARTIST_FILTERS_REDIRECT_TO_NO_ACCESS = "artist-filters-redirect-to-no-access";
export const ANALYTICS_SECTION_ARTIST_FILTERS_REDIRECT_TO_LOTS_LIST = "artist-filters-redirect-to-lots-list";
export const VERSION = "1.1006.714";
export const AUCTIONEER_WRONG_TYPE = "AUCTIONEER_WRONG_TYPE";
export const GTM_MYARTPRICE = "myartprice";
export const GTM_FAIRS = "fairs";
export const GTM_DATA_LAYER_NAME = "dataLayer";
export const TM_CONTAINER_ID = "GTM-KCPT9BR";
export const PAYPAL_CLIENT_ID = "Abmn62QRntLSHNMBFFRn46Ycr5Z0-SXnJi_rxkT-JoWEP-FYnz6rRGhPqLbFqsywC_cFHnv0hVKjUKyz";
export const PAYPAL_MODE = "live";
export const STRIPE_PUBLIC_API_KEY = "pk_live_IOrzk8XBMXdXR9ScU0icTkY6";
export const ARTRON_STAFF_CUSTOMERTYPE_ID = 26;
export const ARTRON_PHYSICSALEGROUPMENTTYPE_ID = 1;
export const ID_FREE_ARTIST = 15079;
export const ARTPRICEDATACLI_CLIENT_TYPES_CLASSIFIEDS_CONSTANT_SHAPE_SQUARE = "square";
export const ARTPRICEDATACLI_CLIENT_TYPES_CLASSIFIEDS_CONSTANT_SHAPE_LANDSCAPE = "landscape";
export const ARTPRICEDATACLI_CLIENT_TYPES_CLASSIFIEDS_SHAPE_PORTRAIT = "portrait";
export const ARTPRICEDATACLI_CLIENT_TYPES_CLASSIFIEDS_SHAPE_OTHER = "other";
export const ARTPRICEDATACLI_CLIENT_TYPES_CLASSIFIEDS_CONSTANT_SIZE_SMALL = "small";
export const ARTPRICEDATACLI_CLIENT_TYPES_CLASSIFIEDS_CONSTANT_SIZE_MEDIUM = "medium";
export const ARTPRICEDATACLI_CLIENT_TYPES_CLASSIFIEDS_CONSTANT_SIZE_LARGE = "large";
export const HOWTO_INDEXES = "indexes";
export const HOWTO_SEARCHES = "searches";
export const HOWTO_ARTPRICE = "artprice";
export const SEARCH_SCOPE_SALES_FUTURES = "search/scope/SEARCH_SCOPE_SALES_FUTURES";
export const SEARCH_SCOPE_SALE_LOTS = "search/scope/SEARCH_SCOPE_SALE_LOTS";
export const SEARCH_SCOPE_MYARTPRICE_STORE = "search/scope/SEARCH_SCOPE_MYARTPRICE_STORE";
export const SEARCH_SCOPE_MYARTPRICE_LOTS_HISTORY = "search/scope/SEARCH_SCOPE_MYARTPRICE_LOTS_HISTORY";
export const SEARCH_SCOPE_MYARTPRICE_LOTS_ALL = "search/scope/MYARTPRICE_LOTS_ALL";
export const SEARCH_SCOPE_MYARTPRICE_LOTS_FUTURE_NEW = "search/scope/MYARTPRICE_LOTS_FUTURE_NEW";
export const SEARCH_SCOPE_MYARTPRICE_LOTS_FUTURE_ALL = "search/scope/MYARTPRICE_LOTS_FUTURE_ALL";
export const SEARCH_SCOPE_MYARTPRICE_LOTS_AUCTIONED_OFF = "search/scope/MYARTPRICE_LOTS_AUCTIONED_OFF";
export const SEARCH_SCOPE_MYARTPRICE_LOTS_MARKETPLACE = "search/scope/MYARTPRICE_LOTS_MARKETPLACE";
export const SEARCH_SCOPE_MYARTPRICE_LOTS_SUMMARY = "search/scope/MYARTPRICE_LOTS_SUMMARY";
export const SEARCH_SCOPE_MYARTPRICE_LOTS_FOLLOW_ALL = "search/scope/MYARTPRICE_LOTS_FOLLOW_ALL";
export const SEARCH_SCOPE_MYARTPRICE_LOTS_FOLLOW_FUTURE = "search/scope/MYARTPRICE_LOTS_FOLLOW_FUTURE";
export const SEARCH_SCOPE_MYARTPRICE_LOTS_FOLLOW_PAST = "search/scope/MYARTPRICE_LOTS_FOLLOW_PAST";
export const SEARCH_SCOPE_MYARTPRICE_LOTS_FOLLOW_MARKETPLACE = "search/scope/MYARTPRICE_LOTS_FOLLOW_MARKETPLACE";
export const SEARCH_SCOPE_MYARTPRICE_MARKETPLACE = "search/scope/MYARTPRICE_MARKETPLACE";
export const SEARCH_SCOPE_MARKETPLACE_MANAGE_ONLINE = "search/scope/MARKETPLACE_MANAGE_ONLINE";
export const SEARCH_SCOPE_MARKETPLACE_MANAGE_WAITING = "search/scope/MARKETPLACE_MANAGE_WAITING";
export const SEARCH_SCOPE_MARKETPLACE_MANAGE_OFFLINE = "search/scope/MARKETPLACE_MANAGE_OFFLINE";
export const SEARCH_SCOPE_MARKETPLACE_MANAGE_MODIFICATION_REQUIRED = "search/scope/MARKETPLACE_MANAGE_MODIFICATION_REQUIRED";
export const SEARCH_SCOPE_MARKETPLACE_MANAGE_WITH_OFFERS = "search/scope/MARKETPLACE_MANAGE_WITH_OFFERS";
export const SEARCH_SCOPE_MARKETPLACE_MANAGE_MY_OFFERS = "search/scope/MARKETPLACE_MANAGE_MY_OFFERS";
export const SEARCH_SCOPE_MARKETPLACE_MANAGE_WINNING_AUCTIONS = "search/scope/MARKETPLACE_MANAGE_WINNING_AUCTIONS";
export const SEARCH_SCOPE_CLASSIFIEDS = "search/scope/CLASSIFIEDS";
export const SEARCH_SCOPE_STORES = "search/scope/STORES";
export const SEARCH_SCOPE_CLASSIFIEDS_IN_STORE = "search/scope/CLASSIFIEDS_IN_STORE";
export const SEARCH_SCOPE_AUCTIONEER_FUTURE_SALES = "search/scope/SEARCH_SCOPE_AUCTIONEER_FUTURE_SALES";
export const SEARCH_SCOPE_AUCTIONEER_PAST_SALES = "search/scope/SEARCH_SCOPE_AUCTIONEER_PAST_SALES";
export const SEARCH_SCOPE_AUCTIONEER_SALE = "search/scope/SEARCH_SCOPE_AUCTIONEER_SALE";
export const SEARCH_SCOPE_AUCTIONEER_SALE_LOTS = "search/scope/SEARCH_SCOPE_AUCTIONEER_SALE_LOTS";
export const SEARCH_SCOPE_LOTS = "search/scope/LOTS";
export const SEARCH_SCOPE_ARTISTS = "search/scope/SEARCH_SCOPE_ARTISTS";
export const SEARCH_SCOPE_ARTIST_LOTS_PAST = "search/scope/ARTIST_LOTS_PAST";
export const SEARCH_SCOPE_ARTIST_LOTS_FUTURE = "search/scope/ARTIST_LOTS_FUTURE";
export const SEARCH_SCOPE_ARTIST_LOTS_ALL = "search/scope/ARTIST_LOTS_ALL";
export const CUSTOMER_EMAIL_STATUS_VALID = "valid";
export const CUSTOMER_EMAIL_STATUS_INPROGRESS = "inprogress";
export const CUSTOMER_EMAIL_STATUS_NONVERIFIED = "nonverified";
export const CUSTOMER_VERIFICATION_STATUS_VALID = "valid";
export const CUSTOMER_VERIFICATION_STATUS_INPROGRESS = "inprogress";
export const CUSTOMER_VERIFICATION_STATUS_NONVERIFIED = "nonverified";
export const CUSTOMER_VERIFICATION_STATUS_MODIFICATIONREQUIRED = "modificationRequired";
export const PROFESSIONAL_TYPE_ID_PROFESSIONAL = 1;
export const PROFESSIONAL_TYPE_ID_PRIVATE = 2;
export const SEARCH_ITEMS_PER_PAGE = [30,60];
export const UPLOADER_URL = "https://upload.artprice.com";
export const UPLOADER_FORCED_USER = "";
export const TEST_AUCTIONEER_CONTROL_CENTER = null;
export const IMAGE_SOURCE_FOR_PDF = "https://images.artprice.io/img/";
export const IMAGE_SOURCE = "https://imgpublic.artprice.com/img/";
export const PDF_SOURCE = "https://imgpublic.artprice.com/pdf/";
export const COOKIE_CONSENT_NAME = "cookie-preferences-v3";
export const COOKIE_CONSENT_ACCEPTED = "accepted";
export const COOKIE_CONSENT_REJECTED = "rejected";
export const MODULE_PASTSALE = 1;
export const MODULE_FUTURESALE = 2;
export const MODULE_BIOGRAPHY = 3;
export const MODULE_INDEXES = 4;
export const MODULE_SIGNATURE = 5;
export const MODULE_INDICATOR = 8;
export const MODULE_CLASSIFIED = 9;
export const MODULE_ESTIMATE = 10;
export const MODULE_AIL = 11;
export const MODULE_IMAGE = 12;
export const MODULE_STORE = 13;
export const MODULE_EXPORT_PDF = 14;
export const AUCTIONEERS_OFFICIAL_COUNT = 7200;
export const LOTS_OFFICIAL_COUNT = 18028800;
export const ARTISTS_OFFICIAL_COUNT = 863300;
export const LOTS_OFFICIAL_YEAR_COUNT = 1252200;
export const THEME_BASICS_STORE = 27;
export const THEME_BASICS = 19;
export const THEME_ONE_DAY = 20;
export const THEME_PREMIUM = 21;
export const THEME_PROFESSIONAL = 23;
export const THEME_STORE = 26;
export const LOTSLISTS_MAXLISTS = 500;
export const LOTSLISTS_MAXLOTS = 120;
export const SENTRY_DSN = "https://8c465cbafc554bd2a90dee5909b5bfee@sentry.artprice.io/7";
export const MAIN_VIEWPORT_DESKTOP_WIDTH = 425;
